<template>
  <div class="elevation-1" v-bind:class="{archived: isDeleted}" style="width: 100%">
    <div class="pa-1 d-flex">

      <div class=""><v-chip style="min-width: 100px;" :color="color" label>{{ relation.issue.name }}</v-chip></div>
      <div class="flex-grow-1 ml-2 pt-1 text-truncate"><span v-if="isDeleted" class="font-weight-bold">[ARCHIVED]</span> {{ relation.issue.description }}</div>

      <div class="d-flex" style="min-width: 200px;">
        <div class="pt-1 pr-2" style="font-weight: 500; font-size: 16px">
          Due Date: <span style="font-weight: 400">{{ relation.issue.dueDate }}</span>
        </div>

        <v-icon
          class="pl-2 expand-toggle"
          v-bind:class="{ active: expanded }"
          @click.native="toggleExpand">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>

    <v-divider v-if="expanded"/>
    <div v-if="expanded">
      <relation-list-item-item
        :relation-id="relation.id"
        @update="$emit('update')"
      />
    </div>
  </div>
</template>

<script>
import {getFlatColor} from "@/utils/status";

import IssueListItemRelationList
  from "@/modules/project-management/components/work-package-details/IssueListItemRelationList";
import RelationLogTable from "@/modules/project-management/components/relation/RelationLogTable";
import {aggregateStatus} from "@/utils/status";
import {mapGetters} from "vuex";
import RelationListItemItem from "./RelationListItemItem";

export default {
  name: "RelationListItem",
  components: {RelationListItemItem, RelationLogTable, IssueListItemRelationList},
  props: {
    relation: Object
  },

  data() {
    return {
      expanded: false
    }
  },
  computed: {
    color() {
      return getFlatColor(aggregateStatus(this.relation.status));
    },
    isFinished() {
      return this.relation.issue.status === 'finished';
    },
    isDeleted() {
      return this.relation.isDeleted;
    },
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped>
.expand-toggle.active {
  transform: rotateX(180deg);
}

.archived {
  opacity: 0.6;
  background-color: rgba(211, 211, 211, 0.1);
}
</style>
