<template>
  <div class="elevation-1" style="width: 100%" v-bind:class="{archived: isDeleted}" >
    <div class="pa-1 d-flex">

      <div class=""><v-chip style="min-width: 100px;" :color="color" label>{{ issue.name }}</v-chip></div>
      <div class="flex-grow-1 ml-2 pt-1 text-truncate"><span v-if="isDeleted" class="font-weight-bold">[ARCHIVED]</span> {{ issue.description }}</div>

      <div class="d-flex" style="min-width: 200px;">
        <div class="pt-1 pr-2" style="font-weight: 500; font-size: 16px">
          Due Date: <span style="font-weight: 400">{{ issue.dueDate }}</span>
        </div>

        <v-icon
          class="pl-2 expand-toggle"
          v-bind:class="{ active: expanded }"
          @click.native="toggleExpand">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>

    <v-divider v-if="expanded && hasAnyInputs"/>
    <div v-if="expanded && hasAnyInputs" class="pa-2">
      <issue-list-item-relation-list :has-rights="hasRights" @update="update" :issue="issue" />
    </div>
    <v-divider v-if="expanded"/>
    <div v-if="expanded" class="pa-1 d-flex justify-end">
      <v-btn v-if="!isDeleted && hasRights" small outlined @click="openModifyModal">Create Change request</v-btn>
      <v-btn small outlined class="ml-1" @click="showChanges">Show Changes</v-btn>
      <v-btn v-if="canFinish && hasRights" @click="finish" small outlined class="ml-1">Finish Milestone</v-btn>
    </div>

  </div>
</template>

<script>
import FinishMilestone from '@/modules/project-management/graphql/FinishMilestone.graphql';
import Intranet from '@/core/intranet';
import {getFlatColor} from "@/utils/status";
import IssueListItemRelationList
  from "@/modules/project-management/components/work-package-details/IssueListItemRelationList";

export default {
  name: "IssueListItem",
  components: {IssueListItemRelationList},
  props: {
    issue: Object,
    hasRights: Boolean
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    color() {
      return getFlatColor(this.issue.status);
    },
    isFinished() {
      return this.issue.status === 'finished';
    },
    hasAnyInputs() {
      return this.issue.relations.length !== 0;
    },
    isDeleted() {
      return this.issue.isDeleted;
    },
    canFinish() {
      return this.issue.type === 'milestone' && this.issue.relations.length === 0 && this.issue.status !== 'finished';
    }
  },
  methods: {
    showChanges() {
      Intranet.modals().openModal('change-modal', {iri: this.issue.id});
    },
    update() {
      this.$emit('update');
    },
    finish() {
      this.$apollo.mutate({
        mutation: FinishMilestone,
        variables: {
          issue: this.issue.id,
          status: 'finished'
        }
      }).then(() => {
        this.$emit('update');
      })
    },
    openModifyModal() {
      Intranet.modals().openModal('issue-change-modal', {
        action: 'modify',
        type: this.issue.type,
        issue: this.issue,
      })
    },
    toggleExpand() {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped>
.expand-toggle.active {
  transform: rotateX(180deg);
}

.archived {
  opacity: 0.6;
  background-color: rgba(211, 211, 211, 0.1);
}
</style>
