<template>
  <div class="pa-5">
    <div v-if="isInitialized">
      <div class="d-flex justify-space-between pb-3">
        <div class="text-h5 text-nowrap text-truncate">
          <span class="font-weight-bold" v-if="workPackage.isDeleted">[ARCHIVED]</span> {{ workPackage.title }}
        </div>
        <div v-if="canModify && !workPackage.isDeleted">
          <v-btn outlined @click="openModifyWorkPackageModal">Modify Work Package</v-btn>
        </div>
      </div>
      <v-row class="align-stretch">
        <v-col cols="6">
          <v-card v-bind:class="{archived: workPackage.isDeleted}" class="fill-height" max-height="456">
            <v-card-text class="overflow-auto fill-height" v-html="workPackage.description" />
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card v-bind:class="{archived: workPackage.isDeleted}" class="fill-height">
            <v-list two-line>
              <template v-for="(membership, index) in workPackage.team.memberships">
                <v-list-item v-bind:key="membership.id">
                  <v-list-item-avatar>
                    <v-img alt="avatar" src="/img/profile.png" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ membership.user.firstname }}
                      {{ membership.user.lastname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ membership.group.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index < workPackage.team.memberships.length - 1" :key="index"/>
              </template>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card v-bind:class="{archived: workPackage.isDeleted}" class="d-flex flex-column align-items-stretch fill-height">
            <div class="pa-4 d-flex justify-space-around">
              <vc-donut :size="200" :thickness="20" unit="px" :sections="statusSections" >Status</vc-donut>
            </div>
            <div class="pa-2 d-flex flex-column flex-grow-1">
              <div class="wp-details-meta-box">
                <table style="width: 100%">
                  <tbody>
                  <tr v-for="item of statusItems">
                    <td class="d-flex"><div :style="'background-color: ' + getPieColor(item.name) + '; width: 12px; height: 12px; border-radius: 10px; margin-top: 6px; margin-right: 5px;'"/> {{ item.name }}</td>
                    <td style="text-align: right">{{ item.value }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="wp-details-meta-box mt-2 flex-grow-1">
                <table style="width: 100%">
                  <tbody>
                  <tr v-for="item of metaItems">
                    <td class="text-h6">{{ item.name }}</td>
                    <td class="text-h6" style="text-align: right">{{ item.value }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-4">
        <issue-list @update="update" v-if="!workPackage.isDeleted" type="deliverable" :has-rights="canModify" :issues="deliverables" :work-package="workPackage" />
        <issue-list @update="update" v-if="!workPackage.isDeleted" type="milestone" :has-rights="canModify" :issues="milestones" :work-package="workPackage" />
        <relation-list :has-rights="canModify" :display-deleted="false" :relations="workPackage.relations" />
        <issue-list @update="update" type="archived" :issues="archived" :has-rights="canModify" :work-package="workPackage" />
        <relation-list @update="update" :display-deleted="true" :has-rights="canModify" :relations="workPackage.relations" />
      </div>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader type="card"/>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card"/>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card"/>
        </v-col>
      </v-row>
      <v-skeleton-loader
        type="text, table-row, table-row, text, table-row, table-row"
      />
    </div>
  </div>
</template>

<script>
import Intranet from '@/core/intranet';
import WorkPackageDetailsQuery from '@/modules/project-management/graphql/WorkPackageDetails.graphql'
import {mapGetters} from "vuex";
import IssueList from "@/modules/project-management/components/work-package-details/IssueList";
import {getHardColor} from "@/utils/status";
import RelationList
  from "@/modules/project-management/components/work-package-details/RelationList";
import Vue from "vue";

export default {
  name: "WorkPackageDetails",
  components: {RelationList, IssueList},
  data() {
    return {
      isUpdate: false,
    }
  },
  apollo: {
    workPackage: {
      query: WorkPackageDetailsQuery,
      skip() {
        return typeof this.active === 'undefined';
      },
      variables() {
        return {
          id: this.active.id
        }
      }
    }
  },
  watch: {
    reloadEvent() {
      this.update();
    }
  },
  computed: {
    ...mapGetters('workPackage', ['active', 'reloadEvent']),
    ...mapGetters('auth', ['user', 'isAdmin']),
    ...mapGetters('unit', {
      activeUnit: 'active'
    }),
    canModify() {
      return this.isAdmin || this.user.memberships.filter(m => m.unit === this.activeUnit.id).length >= 1;
    },
    isInitialized() {
      return this.isUpdate ? true : typeof this.active !== 'undefined' && !this.$apollo.loading;
    },
    deliverables() {
      return this.workPackage.issues.filter(i => i.type === 'deliverable' && !i.isDeleted);
    },
    milestones() {
      return this.workPackage.issues.filter(i => i.type === 'milestone' && !i.isDeleted);
    },
    archived() {
      return this.workPackage.issues.filter(i => i.isDeleted);
    },
    metaItems() {
      return [
        {name: 'Start', value: this.workPackage.startDate },
        {name: 'End', value: this.workPackage.endDate }
      ]
    },
    statusItems() {
      return [
        {name: 'In Progress', value: this.workPackage.statusList.progress ?? 0 },
        {name: 'Pending', value: this.workPackage.statusList.pending ?? 0 },
        {name: 'Finished', value: this.workPackage.statusList.finished ?? 0 },
        {name: 'Delayed', value: this.workPackage.statusList.delayed ?? 0 }
      ]
    },
    statusSections() {
      let pie = [];
      let counter = 0;
      for (const [key, value] of Object.entries(this.workPackage.statusList)) {
        counter += value;
      }

      for (const [key, value] of Object.entries(this.workPackage.statusList)) {
        pie.push({value: value / counter * 100, color: getHardColor(key)});
      }

      if (counter === 0) {
        pie.push({value: 100, color: '#F5F5F5'})
      }

      return pie;
    },
  },
  methods: {
    update() {
      this.isUpdate = true;
      this.$apollo.queries.workPackage.refetch().then(() => {
        this.isUpdate = false;
      });
    },
    getPieColor(name) {
      return getHardColor(name);
    },
    openModifyWorkPackageModal() {
      Intranet.modals().openModal('work-package-change-modal', {
        action: 'modify',
        workPackage: this.workPackage
      });
    }
  }
}
</script>

<style scoped>
.wp-details-meta-box {
  display: flex;
  justify-content: space-around;
  background-color: #CFD8DC;
  border-radius: 5px;
  min-width: 160px;
  padding: 6px;
}

.archived {
  opacity: 0.65;
}

</style>
