<template>
  <div>
    <div v-if="isAwaitingSent || isAwaitingReceived || isDelayed" class="mb-1 mt-1">
      <v-chip v-if="isAwaitingSent" class="mr-1" color="#ECEFF1" small>Awaiting Sent</v-chip>
      <v-chip v-if="isAwaitingReceived" class="mr-1" color="#ECEFF1" small>Awaiting Receive</v-chip>
      <v-chip v-if="isDelayed" class="mr-1" color="#ECEFF1" small>Delayed</v-chip>
    </div>

    <v-divider v-if="isAwaitingSent || isAwaitingReceived || isDelayed"/>

    <v-data-table
      :headers="headers"
      :items="relation.logs"
      hide-default-footer
    >
      <template v-slot:item.user="{ item }">
        <div class="d-flex align-center py-1">
          <v-avatar class="elevation-1 grey lighten-3" size="40">
            <v-img src="/img/profile.png"/>
          </v-avatar>
          <div class="ml-1">
            <div class="font-weight-bold">{{ item.author.firstname }} {{ item.author.lastname }}</div>
            <div class="caption">
              <p>{{ item.author.email }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ item.date }}</div>
      </template>

      <template v-slot:item.action="{ item }">
        <span><v-icon class="pb-1" dense>mdi-arrow-right</v-icon>{{ item.action }}</span>
      </template>

      <template v-slot:header.actions="{ }"></template>
      <template v-slot:item.actions="{ item }">
        <span><v-icon class="pb-1" dense>mdi-delete</v-icon></span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "RelationLogTable",
  props: {
    relation: Object,
  },
  data() {
    return {
      headers: [
        {text: 'Action', value: 'action'},
        {text: 'User', sortable: false, value: 'user'},
        {text: 'Date', value: 'date'},
        {text: 'actions', value: 'actions', width: 52}
      ]
    }
  },
  computed: {
    isFinished() {
      return !this.isAwaitingReceived && !this.isAwaitingSent
    },
    isAwaitingSent() {
      return this.relation.status.hasOwnProperty('awaiting-sent');
    },
    isAwaitingReceived() {
      return this.relation.status.hasOwnProperty('awaiting-received');
    },
    isDelayed() {
      return this.relation.status.hasOwnProperty('delayed');
    }
  }
}
</script>

<style scoped>

</style>
