<template>
  <div>

    <div v-if="isUpdate ? true : !$apollo.loading">
      <v-expansion-panels accordion multiple>
        <issue-list-item-relation-list-item
          v-for="relation in relations"
          :relation="relation"
          :has-rights="hasRights"
          @update="update"
        />
      </v-expansion-panels>
    </div>
    <div v-else>
      <v-skeleton-loader type="list-item, list-item, list-item"/>
    </div>
  </div>
</template>

<script>
import IssueListItemRelationListItem from '@/modules/project-management/components/work-package-details/IssueListItemRelationListItem';
import CreateLogEntry from '@/modules/project-management/graphql/CreateLogEntry.graphql';
import RelationWithLogsQuery from "@/modules/project-management/graphql/RelationWithLogs.graphql";
import {aggregateStatus, getFlatColor} from "@/utils/status";
import RelationLogTable from "@/modules/project-management/components/relation/RelationLogTable";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IssueListItemRelationList",
  components: {RelationLogTable, IssueListItemRelationListItem},
  props: {
    issue: Object,
    hasRights: Boolean
  },
  apollo: {
    relations: {
      query: RelationWithLogsQuery,
      update: data => {
        return data.issue.relations;
      },
      variables() {
        return {
          id: this.issue.id
        }
      }
    }
  },
  data() {
    return {
      isUpdate: false,
    }
  },
  watch: {
    reloadEvent() {
      this.isUpdate = true;
      this.$apollo.queries.relations.refetch().then(() => {
        this.isUpdate = false;
      })
    }
  },
  computed: {
    ...mapGetters('workPackage', ['reloadEvent']),
  },
  methods: {

    ...mapGetters('unit', {
      activeUnit: 'active'
    }),
    ...mapGetters('auth', ['user']),
    ...mapGetters('workPackage', {
      findWorkPackage: 'find'
    }),
    canModify() {
      return this.user?.memberships?.filter(m => m.unit === this.activeUnit.id).length >= 1 ?? false;
    },

    /////
    // Other
    /////
    generateInputColor(input) {
      return getFlatColor(aggregateStatus(input.status));
    },

    /////
    // Actions
    /////
    update() {
      this.isUpdate = true;
      this.$apollo.queries.relations.refetch().then(() => {
        this.isUpdate = false;
        this.$emit('update');
      })
    }
  }
}
</script>

<style scoped>
.archived {
  opacity: 0.4;
}
</style>
