<template>
<div v-if="!$apollo.loading || isUpdate">
  <relation-log-table :relation="relation" />
  <v-divider v-if="canReceiveRelation || canSendRelation" />
  <div class="pa-1 d-flex justify-end" v-if="canReceiveRelation || canSendRelation">
    <v-btn small outlined v-if="canSendRelation" @click="onClickMarkAsSent">MARK AS SENT</v-btn>
    <v-btn small outlined v-if="canReceiveRelation" @click="onClickMarkAsReceived">MARK AS RECEIVED</v-btn>
  </div>
</div>
</template>

<script>
import CreateLogEntry from '@/modules/project-management/graphql/CreateLogEntry.graphql';
import RelationWithLogsQuery from "@/modules/project-management/graphql/RealRelationWithLogs.graphql";
import RelationLogTable from "@/modules/project-management/components/relation/RelationLogTable";
import {mapGetters} from "vuex";

export default {
  name: "RelationListItemItem",
  components: {RelationLogTable},
  props: {
    relationId: String
  },
  data() {
    return {
      isUpdate: false
    }
  },
  apollo: {
    relation: {
      query: RelationWithLogsQuery,
      variables() {
        return {
          id: this.relationId
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('workPackage', {
      findWorkPackage: 'find'
    }),
    canReceiveRelation() {
      let wp = this.findWorkPackage(this.relation.workPackage.id);
      return this.relation.status.hasOwnProperty('awaiting-received') &&
        this.user?.memberships?.filter(m => m.team === wp.team.id).length >= 1;
    },
    canSendRelation() {
      let wp = this.findWorkPackage(this.relation.issue.workPackage.id);
      return this.relation.status.hasOwnProperty('awaiting-sent') &&
        this.user?.memberships?.filter(m => m.team === wp.team.id).length >= 1;
    },
  },
  methods: {
    /////
    // Actions
    /////
    onClickMarkAsSent() {
      this.$apollo.mutate({
        mutation: CreateLogEntry,
        variables: {
          action: 'sent',
          relation: this.relation['id']
        }
      }).then(value => {
        this.isUpdate = true;
        this.$apollo.queries.relation.refetch().then(() => {
          this.isUpdate = false;
          this.$emit('update');
        })
      })
    },
    onClickMarkAsReceived() {
      this.$apollo.mutate({
        mutation: CreateLogEntry,
        variables: {
          action: 'received',
          relation: this.relation['id']
        }
      }).then(value => {
        this.isUpdate = true;
        this.$apollo.queries.relation.refetch().then(() => {
          this.isUpdate = false;
          this.$emit('update');
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
