<template>
<div v-if="issues.length !== 0 || type !== 'archived'">
  <div class="d-flex justify-space-between pb-1 pt-2">
    <div class="text-h6 text-nowrap text-truncate">
      {{ name }}s
    </div>
    <div v-if="type !== 'archived'">
      <v-btn v-if="(!workPackage.isDeleted || type !== 'archived') && hasRights" small outlined @click="openCreateModal">Create {{ name }}</v-btn>
    </div>
  </div>

  <div v-if="issues.length === 0">
    This work package does not have any {{ name }}s
  </div>

  <div class="ma-2">
    <issue-list-item @update="update"
      v-for="issue of issues"
      :issue="issue"
      :has-rights="hasRights"
      v-bind:key="issue._id"
    />
  </div>
</div>
</template>

<script>
import Intranet from '@/core/intranet';
import IssueListItem from "@/modules/project-management/components/work-package-details/IssueListItem";

export default {
  name: "IssueList",
  components: {IssueListItem},
  props: {
    workPackage: Object,
    type: String,
    issues: Array,
    hasRights: Boolean
  },
  data() {
    return {
      names: {
        deliverable: 'Deliverable',
        milestone: 'Milestone',
        archived: 'Archived Deliverables & Milestone'
      }
    }
  },
  computed: {
    name() {
      return this.names[this.type];
    }
  },
  methods: {
    update() {
      this.$emit('update');
    },
    openCreateModal() {
      Intranet.modals().openModal('issue-change-modal', {
        action: 'create',
        type: this.type,
        workPackage: this.workPackage
      });
    }
  }
}
</script>

<style scoped>

</style>
