<template>
  <div v-if="filteredRelations.length > 0">
    <div class="d-flex justify-space-between pb-1 pt-2">
      <div class="text-h6 text-nowrap text-truncate">
        {{ displayDeleted ? 'Archived' : '' }} Inputs
      </div>
    </div>

    <div class="ma-2">
      <relation-list-item
        v-for="relation of filteredRelations"
        :relation="relation"
        v-bind:key="relation._id"
        @update="$emit('update')"
      />
    </div>
  </div>
</template>

<script>
import RelationListItem from "@/modules/project-management/components/work-package-details/RelationListItem";
export default {
  name: "RelationList",
  components: {RelationListItem},
  props: {
    relations: Array,
    displayDeleted: Boolean,
    hasRights: Boolean
  },
  computed: {
    filteredRelations() {
      return this.relations.filter(r => this.displayDeleted ? r.isDeleted : !r.isDeleted);
    }
  }
}
</script>

<style scoped>

</style>
